import React from 'react'
import './Featured.scss'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { CircularProgressbar } from 'react-circular-progressbar';
import "react-circular-progressbar/dist/styles.css"
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';

const Featured = () => {
    return (
        <div className='featured'>
            <div className="top">
                Total Revenue
                <h1>
                    <MoreVertIcon fontSize='small' />
                </h1>
            </div>
            <div className="bottom">
                <div className="circle">
                    <CircularProgressbar className='featuredChart' value={70} text={"70%"} strokeWidth={5} />
                </div>
                <p className="title">Total Sales Today</p>
                <p className="amount">$420</p>
                <p className="desc">
                    Previous transactions processing. Last payments may not be included
                </p>
                <div className="summary">
                    <div className="item">
                        <div className="itemTitle">Target</div>
                        <div className="itemResult negative">
                            <KeyboardArrowDownOutlinedIcon fontSize='small'/>
                            <div className="resultAmount">$12.5k</div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="itemTitle">Last Week</div>
                        <div className="itemResult positive">
                            <KeyboardArrowUpOutlinedIcon fontSize='small'/>
                            <div className="resultAmount">$12.5k</div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="itemTitle">Last Month</div>
                        <div className="itemResult negative">
                            <KeyboardArrowDownOutlinedIcon fontSize='small'/>
                            <div className="resultAmount">$12.5k</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Featured
